<template>
  <Layout>
    <div>
      <Hyheader
        :header-items="headerItems"
        :header-form="formData"
        :inline="true"
        label-width="100px"
      >
        <template #submit>
          <div class="headerFlex">
            <el-button
              type="primary"
              @click="getList"
            >
              搜索
            </el-button>
          </div>
        </template>
      </Hyheader>
    </div>
    <Hyform
      ref="tableElement"
      v-loading="listInfo.loading"
      :form-item-prop="formItemProp"
      :table-data="tableData"
      @selection-change="handleSelectionChange"
    />
    <div
      class="btn-box"
      style="margin:24px 0"
    >
      <div>
        <el-button
          type="primary"
          @click="toggleSelection('all')"
        >
          {{ multipleSelection.length === tableData.length ? '取消全选' : '全选' }}
        </el-button>
        <template v-if="status === 0">
          <el-button
            type="primary"
            @click="batchUpdata(2)"
          >
            设为黑名单
          </el-button>
          <el-button
            type="primary"
            @click="batchUpdata(1)"
          >
            设为白名单
          </el-button>
        </template>
        <el-button
          v-else
          type="primary"
          @click="batchUpdata(0)"
        >
          解除{{ title }}
        </el-button>
      </div>
      <div v-if="status !== 0">
        <!-- TODO: -->
        <!-- <el-button
          type="primary"
          @click="onSubmit(1)"
        >
          下载{{ title }}
        </el-button> -->
        <el-button
          type="primary"
          @click="dialogVisible = true"
        >
          添加{{ title }}
        </el-button>
      </div>
    </div>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="getList"
    />
    <el-dialog
      :title="status === 1 ? '添加白名单' : '添加黑名单'"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <Hyheader
        ref="formElement"
        :header-items="subHeaderItems"
        :header-form="subFormData"
        :inline="false"
        label-width="100px"
      >
        <template #submit>
          <div class="headerFlex">
            <el-button
              type="primary"
              @click="onSubmit"
            >
              保存
            </el-button>
          </div>
        </template>
      </Hyheader>
    </el-dialog>
  </Layout>
</template>

<script>
import { commonRequestGet, commonRequestPost, commonRequestPut } from '@/api/commonApi.js'
import { getAppKeys } from '@/api/blacklist.js'
import { compact } from 'lodash'

const type = {
  device_id: '设备ID',
  ip: 'IP'
}
export default {
  props: { status: { type: Number, default: 0 }, title: { type: String, default: '' } },
  data () {
    return {
      tableData: [],
      appKeysNameMap: [],
      multipleSelection: [],
      formData: {
        start_time: '',
        end_time: '',
        status: this.status,
        version: '',
        app_id: '',
        type: '',
        page: 1,
        pageSize: 20
      },
      listInfo: {
        total: 0,
        loading: false,
        yesterdayUserCount: 0
      },
      subFormData: {
        type: '',
        value: '',
        status: this.status

      },
      subHeaderItems: [
        {
          label: '类型',
          value: 'type',
          children: type
        },
        {
          label: '内容',
          value: 'value'
        }
      ],
      rules: {
        app_name: [{ required: true, message: '必填', trigger: 'blur' }],
        app_id: [{ required: true, message: '必填', trigger: 'blur' }]
      },
      dialogVisible: false,
      formItemProp: compact([
        {
          type: 'selection'
        },

        {
          label: '设备ID/IP',
          prop: 'value'
        },
        {
          label: '类型',
          prop: 'type',
          formatter: (row) => type[row.type]
        },
        {
          label: '登录应用',
          prop: 'version',
          formatter: (row) => this.appKeysNameMap[row.app_id]
        },
        {
          label: '版本号',
          prop: 'version'
        },
        {
          label: '登录时间',
          prop: 'visit_time'
        },
        this.status === 0 ? {
          label: '访问次数',
          prop: 'visit_count'
        } : {
          label: '标记人',
          prop: 'operator',
          formatter: (row) => row.operator.username
        },
        this.status === 0 ? '' : {
          label: '标记时间',
          prop: 'operation_time'
        },
        this.status === 0 ? '' : {
          label: '状态',
          prop: 'status',
          validate: 'select',
          optionChildren: [
            {
              value: '白名单',
              key: 1
            },
            {
              value: '黑名单',
              key: 2
            }
          ]
        }
      ])
    }
  },
  computed: {
    headerItems () {
      return [
        {
          label: '',
          // value: 'app_name',
          value: 'start_time',
          value1: 'end_time',
          placeholder: '请输入',
          validate: 'time',
          type: 'date'
        },
        {
          label: '',
          placeholder: '请选择应用',
          value: 'app_id',
          children: { '': '全部', ...this.appKeysNameMap }
        },
        {
          label: '',
          placeholder: '请输入版本号',
          value: 'version'
          // children: this.appKeysNameMap
        },
        {
          label: '',
          placeholder: '',
          value: 'type',
          children: { '': '全部', ...type }
        }
      ]
    }
  },
  created () {
    this.getList()
    this.getAppKeys()
  },
  methods: {
    async getList () {
      try {
        this.listInfo.loading = true
        const res = await commonRequestGet('admin/app-review-records', this.formData)
        this.tableData = res.data
        this.listInfo.loading = false
        this.listInfo.total = res.total
      } catch (err) {
        console.log(err)
        this.listInfo.loading = false
      }
    },
    getAppKeys () {
      getAppKeys({
        platform: ''
      }).then(res => {
        if (res.code === 200) {
          const appKeysList = res?.data?.list ?? []
          this.appKeysNameMap = appKeysList.reduce((result, item) => {
            result[item.appKey] = item.name
            return result
          }, {})
        }
      })
    },

    // EditItem (row) {
    //   if (row) {
    //     for (const key in this.subFormData) {
    //       if (Object.hasOwnProperty.call(row, key)) {
    //         this.subFormData[key] = row[key]
    //       }
    //     }
    //     // this.subFormData = cloneDeep(row)
    //   }
    //   this.dialogVisible = true
    // },
    batchUpdata (status) {
      const title = status === 1 ? '白名单' : '黑名单'
      const msg = status === 0 ? '请确认将所选ID解除' : `请确认将所选ID添加为${title}`
      // if (status) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        try {
          const data = []
          this.multipleSelection.map(item => {
            data.push({
              id: item.id,
              status
            })
          })
          await commonRequestPut('admin/app-review-records', data)
          this.$message.success('保存成功')
          this.getList()
        } catch (error) {
          this.$message.error(error.response.data.message)
        }
      })
      // } else {
      //   const form = this.$refs.formElement
      //   form.$refs.formRef.validate(async (valid) => {
      //     if (valid) {
      //       try {
      //         await commonRequestPost('admin/app-review-records', this.subFormData)
      //         this.dialogVisible = false
      //         this.$message.success('保存成功')

      //         // this.listInfo.loading = false
      //         this.getList()
      //       } catch (error) {
      //         this.$message.error(error.response.data.message)
      //       }
      //     }
      //   })
      // }
    },
    onSubmit () {
      const form = this.$refs.formElement
      form.$refs.formRef.validate(async (valid) => {
        if (valid) {
          try {
            // 8de2eba1c04cd41b7425707948b722df
            if (this.subFormData.id) {
              await commonRequestPut(`admin/app-review-records/${this.subFormData.id}`, this.subFormData)
            } else {
              await commonRequestPost('admin/app-review-records', this.subFormData)
            }
            this.dialogVisible = false
            this.$message.success('保存成功')

            // this.listInfo.loading = false
            this.getList()
          } catch (error) {
            this.$message.error(error.response.data.message)
          }
        }
      })
    },
    handleClose () {
      this.subFormData = {
        type: '',
        value: '',
        status: this.status
      }
      this.$refs.formElement.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    toggleSelection () {
      this.$refs.tableElement.$refs.tableRef.toggleAllSelection()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style lang="less" scoped>
.btn-box{
  display: flex;
  justify-content: space-between;
}
</style>
